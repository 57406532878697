<template>
  <RouterView />
  <KTBrowserWarningMessageModal />
</template>
<script lang="ts">
import {
  defineComponent,
  nextTick,
  onBeforeMount,
  onMounted,
  computed,
} from "vue";
import { useI18n } from "vue-i18n";
import { RouterView } from "vue-router";
import { useAuthStore } from "@/stores/auth";
import { useBodyStore } from "@/stores/body";
import { useConfigStore } from "@/stores/config";
import { useFirebaseStore } from "@/stores/firebase";
import { useThemeStore } from "@/stores/theme";
import { useNotificationsStore } from "@/stores/notifications";
import ApiService from "@/core/services/ApiService";
import FirebaseService from "@/core/services/FirebaseService";
import JwtService from "@/core/services/JwtService";
import UserService from "@/core/services/UserService";
import KTBrowserWarningMessageModal from "@/components/modals/warning-message/BrowserWarningMessageModal.vue";
import {
  getToken,
  onMessage,
  isSupported,
} from "firebase/messaging";
import { messaging } from "./firebase";
import { themeConfigValue } from "@/core/helpers/config";
import { initializeComponents } from "@/core/plugins/keenthemes";
import { polyfillCountryFlagEmojis } from "country-flag-emoji-polyfill";
import { ElNotification } from "element-plus";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { Modal } from "bootstrap";

export default defineComponent({
  name: "app",
  components: {
    RouterView,
    KTBrowserWarningMessageModal,
  },
  setup() {
    const i18n = useI18n();
    const configStore = useConfigStore();
    const themeStore = useThemeStore();
    const bodyStore = useBodyStore();
    const authStore = useAuthStore();
    const firebaseStore = useFirebaseStore();
    const notificationsStore = useNotificationsStore();

    const activeTaskCount = computed(() => {
      return useAuthStore().getActiveTaskCount;
    });

    onBeforeMount(() => {
      /**
       * Overrides the layout config using saved data from localStorage
       * remove this to use static config (@/core/config/DefaultLayoutConfig.ts)
       */
      configStore.overrideLayoutConfig();
      /**
       *  Sets a mode from configuration
       */
      themeStore.setThemeMode(themeConfigValue.value);
    });

    onMounted(() => {
      const browser = detectBrowser();

      if (browser !== "Chrome" && browser !== "Firefox") {
        const modal = new Modal(
          document.getElementById(
            "kt_modal_browser_warning_message"
          ) as HTMLElement
        );
        modal.show();
      }

      if (JwtService.getToken()) {
        ApiService.setHeader();
        authStore.userMe();
        // Check if Notification API is supported
        if (typeof Notification !== "undefined") {
          if (Notification.permission !== "granted") {
            openPermissionModal();
          } else {

            isSupported()
              .then((isSupported) => {
                if (isSupported) {

                  return getToken(messaging, {
                    vapidKey: import.meta.env.VITE_APP_FIREBASE_VALID_KEY,
                  }).then((currentToken) => {
                    const randomNum =
                      Math.floor(Math.random() * 9000000000) + 1000000000;
                    if (currentToken) {
                      // Token kontrol ve kaydetme mantığınız
                      if (
                        FirebaseService.getFirebaseToken() &&
                        FirebaseService.getFirebaseToken() === currentToken
                      ) {
                        // Aynı token, bir şey yapmanıza gerek yok
                      } else {
                        const user = UserService.getUserInfo()
                          ? UserService.getUserInfo()
                          : {};

                        const data = {
                          name: user ? user["username"] : "",
                          token: currentToken,
                          type: "WEB",
                        };

                        firebaseStore.saveFirebaseInfo(data).then(() => {
                          FirebaseService.saveFirebaseToken(currentToken);
                        });
                      }
                    }

                    // Mesaj dinleyici
                    onMessage(messaging, (payload) => {
                      const text = payload?.notification?.body;

                      ElNotification({
                        title: payload?.notification?.title,
                        dangerouslyUseHTMLString: true,
                        message: `<div>${text !== undefined ? text : ""}</div>`,
                        duration: 10000,
                        type: "info",
                      });

                      if (payload?.data?.type_identifier === "TASK") {
                        useAuthStore().setActiveTaskCount(
                          activeTaskCount.value + 1
                        );
                      }

                      if (payload?.data?.type_identifier === "NOTIFICATION") {
                        getNotifications();
                      }
                    });
                  });
                } else {
                  console.log(
                    "This browser does not support Firebase messaging."
                  );
                }
              })
              .catch((err) => {
                console.error("Firebase messaging error:", err);
              });
          }
        }

        polyfillCountryFlagEmojis();
      }

      nextTick(() => {
        initializeComponents();
        bodyStore.removeBodyClassName("page-loading");
      });
    });

    const detectBrowser = () => {
      const userAgent = navigator.userAgent;

      if (/firefox/i.test(userAgent)) {
        return "Firefox";
      } else if (/opr|opera/i.test(userAgent)) {
        return "Opera";
      } else if (/edg/i.test(userAgent)) {
        return "Edge";
      } else if (
        /chrome/i.test(userAgent) &&
        !/opr|opera|edg/i.test(userAgent)
      ) {
        return "Chrome";
      } else if (/safari/i.test(userAgent) && !/chrome/i.test(userAgent)) {
        return "Safari";
      }
      return "";
    };

    const getNotifications = async () => {
      try {
        const response = await notificationsStore.getNotificationList(
          "?is_read=false"
        );
        notificationsStore.refreshNotificationList({
          notifications: response.results,
          next: response.next,
          previous: response.previous,
        });
      } catch (_) {
        notificationsStore.refreshNotificationList({
          notifications: [],
          next: null,
          previous: null,
        });
      }
    };

    const openPermissionModal = () => {
      if (typeof Notification === "undefined") {
        return;
      }

      Swal.fire({
        text: i18n.t("notifications.permissionMessage"),
        html: `<di>
              <div class="mb-5">${i18n.t(
                "notifications.permissionMessage"
              )}</div>
              <div class="text-center mb-5"><div class="fw-bold mb-3">Chrome</div><img src="/media/images/chrome-notifiation-permission.png" width="200px" /></div>
              <div class="text-center"><div class="fw-bold mb-3">Firefox</div><img src="/media/images/firefox-notifiation-permission.png" width="200px" /></div>
               </di>`,
        buttonsStyling: false,
        confirmButtonText: i18n.t("buttons.okay"),
        allowOutsideClick: false,
        customClass: {
          confirmButton: "btn btn-primary",
        },
      }).then(() => {
        Notification.requestPermission();
      });
    };
  },
});
</script>
<style lang="scss">
@import "bootstrap-icons/font/bootstrap-icons.css";
@import "apexcharts/dist/apexcharts.css";
@import "quill/dist/quill.snow.css";
@import "animate.css";
@import "sweetalert2/dist/sweetalert2.css";
@import "nouislider/distribute/nouislider.css";
@import "@fortawesome/fontawesome-free/css/all.min.css";
@import "socicon/css/socicon.css";
@import "line-awesome/dist/line-awesome/css/line-awesome.css";
@import "dropzone/dist/dropzone.css";
@import "@vueform/multiselect/themes/default.css";
@import "prism-themes/themes/prism-shades-of-purple.css";
@import "element-plus/dist/index.css";
// Main demo style scss
@import "assets/fonticon/fonticon.css";
@import "assets/keenicons/duotone/style.css";
@import "assets/keenicons/outline/style.css";
@import "assets/keenicons/solid/style.css";
@import "assets/sass/element-ui.dark";
@import "assets/sass/plugins";
@import "assets/sass/style";
#app {
  display: contents;
}
</style>
