<template>
  <!--begin::Modal - Browser Warning Message Modal-->
  <div
    class="modal fade"
    tabindex="-1"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    data-bs-focus="false"
    aria-labelledby="browserWarningMessageModalLabel"
    id="kt_modal_browser_warning_message"
    ref="browserWarningMessageModalRef"
  >
    <!--begin::Modal dialog-->
    <div class="modal-dialog modal-md">
      <!--begin::Modal content-->
      <div class="modal-content rounded">
        <!--begin::Modal header-->
        <div class="modal-header">
          <h4 class="modal-title">{{ $t("warning") }}</h4>
          <button
            class="btn btn-icon btn-sm btn-active-light-primary ms-2"
            data-bs-dismiss="modal"
          >
            <KTIcon icon-name="cross" icon-class="fs-2x" />
          </button>
        </div>
        <!--end::Modal header-->

        <!--begin::Modal body-->
        <div class="modal-body">
          <div class="text-center my-5">
            <KTIcon icon-name="information-3" icon-class="fs-2hx mb-3" />
            <div class="fs-6 mb-0">
              {{ $t("browserWarningMsg") }}
            </div>
          </div>
        </div>
        <!--end::Modal body-->
      </div>
      <!--end::Modal content-->
    </div>
    <!--end::Modal dialog-->
  </div>
  <!--end::Modal - Browser Warning Message Modal-->
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "KTBrowserWarningMessageModal",
  components: {},

  setup() {
    return {};
  },
});
</script>
