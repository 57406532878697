import { createApp } from "vue";
import { createPinia } from "pinia";
import { Tooltip } from "bootstrap";
import App from "./App.vue";

import router from "./router";
import ElementPlus from "element-plus";
import ElementPlusLangTr from "element-plus/es/locale/lang/tr";
import ElementPlusLangEng from "element-plus/es/locale/lang/en";
import i18n from "@/core/plugins/i18n";

//imports for app initialization
import ApiService from "@/core/services/ApiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";
import { initKtIcon } from "@/core/plugins/keenthemes";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import weekOfYear from "dayjs/plugin/weekOfYear";
import updateLocale from "dayjs/plugin/updateLocale";
import localeData from "dayjs/plugin/localeData";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import isoWeek from 'dayjs/plugin/isoWeek';

import VOtpInput from "vue3-otp-input";

import "dayjs/locale/tr";
import "dayjs/locale/en";

import "@/core/plugins/prismjs";
import * as Sentry from "@sentry/vue";

let locale =
  navigator.languages && navigator.languages.length
    ? navigator.languages[0]
    : navigator.language;

locale = locale.includes("-") ? locale.split("-")[0] : locale;

const languages = ["en", "tr"];

if (!window.localStorage.getItem("lang")) {
  window.localStorage.setItem(
    "lang",
    languages.includes(locale) ? locale : "tr"
  );
}
const currentLang = window.localStorage.getItem("lang");

// Dayjs
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(weekOfYear);
dayjs.extend(updateLocale)
dayjs.extend(localeData)
dayjs.extend(quarterOfYear)
dayjs.extend(isoWeek);
dayjs.tz.setDefault("Europe/Istanbul")

if (currentLang === "tr") {
  dayjs.locale("tr");
} else {
  dayjs.locale("en");
}

// Set Monday as first day of week
dayjs.updateLocale("en", {
  weekStart: 1,
});

const app = createApp(App);

Sentry.init({
    app,
    dsn: "https://58c70df667b9890386a42d6e58507b2a@o1109068.ingest.sentry.io/4505720046616576",
    integrations: [
        new Sentry.BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
        new Sentry.Replay({maskAllText: false, maskAllInputs: false}),
    ],
    tracesSampleRate: 0.1,
    tracePropagationTargets: ['127.0.0.1', 'localhost', /^https:\/\/apibeta\.empadigital\.com/, /^https:\/\/services\.empadigital\.com/],
    replaysSessionSampleRate: 0.1,
    environment: import.meta.env.MODE,
    trackComponents: true,
    replaysOnErrorSampleRate: 1.0,
    enabled: import.meta.env.MODE === 'production'
});

app.use(createPinia());
app.use(router);
app.use(ElementPlus, {
  locale: currentLang == "tr" ? ElementPlusLangTr : ElementPlusLangEng,
});
app.component('v-otp-input', VOtpInput)

ApiService.init(app);
initApexCharts(app);
initInlineSvg(app);
initKtIcon(app);
initVeeValidate();

app.use(i18n);

app.directive("tooltip", (el) => {
  new Tooltip(el);
});

app.mount("#app");
